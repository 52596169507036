/* YOUTUBE VIDEO INFO (API) */
.youtube-video-card {
    text-align: left;
  }
  
  .youtube-video-card .card {
    flex-direction: row;
    background-color: silver;
  }
  
  .publishedDate {
    font-size: x-small;
    font-style: italic;
  }

  /* videos container div */
  .videosContainerColRow {
    margin-top: 7%;
  }
  
  /* size from YouTube */
  .youtube-video-card .card img {
    width: 120px; 
    height: 90px;
  }

  /* PAGINATION */
  .button-totalvideos {
    padding: 5px !important;
    margin-left: 15px;
  }

  .pagination-container {
    text-align: center;
  }
  .pages-display {
    display: inline-block;
  }