body {
  background-color: black;
}

.app {
  text-align: center;
}

.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.twelveCoffeesIcon {
  width: 25%;
  padding: 10px;
}

/* YOUTUBE Embedded */
.video-responsive {
  overflow: hidden;
  position: relative;
  pointer-events: auto;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  width: 500px;
  height: 200px;
}

/* UNDER CONSTRUCTION */
.underConstruction img {
  width: 5%;
}