.home {
  text-align: center;
  background-color: black;
  background-image: url("../images/RustyTruck.png");
  position: relative;
  background-repeat: repeat-y;
  background-size: cover;
  margin-top: 110px;
}

/* CAROUSEL */
.home-carousel-item {
  padding: 1%;
  width: 100vw;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.home-carousel-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.hero-blurb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 15px;
}

.video-coming-soon-caption {
  position: absolute;
  top: 0;
  bottom: 0;
}

.video-coming-soon-caption h1 {
  color: yellow;
  padding-top: 3%;
}

/* Hosts card */
.card-hosts,
.card-podcast {
  /* white background with 50% opacity (transparent) */
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1%;
}

.card-host-single {
  width: 18rem;
  margin: 0.2%;
}

.card-podcast-group {
  background-color: rgba(255, 255, 255, 0.75);
  margin: 1%;
}

.small-card {
  max-width: 22%;
}

/* YOUTUBE Embedded */
.video-responsive {
  overflow: hidden;
  position: relative;
  height: inherit;
}

.video-responsive iframe {
  display: flex;
  min-height: 100%;
  min-width: 100%;
}

/* On screens that are 400px wide or less, the background image will be a smaller one (ToDo) */
@media screen and (max-width: 400px) {
  .home {
    background-image: url("../images/12Coffees_small_screen.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* On screens that are 401px wide or more, the embedded video frame and merch image will be resized a little */
@media screen and (min-width: 401px) {
  .home-video {
    width: 80%;
    height: 100%;
    float: left;
  }
}

.img-wrapper{
  overflow:hidden;
}

img.card-image {
  transition: all 0.4s ease 0s;
  width: 100%;
}

img.card-image:hover {
  transform: scale(1.25);
}

.loading {
  margin: auto;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Podcast players */
.podcast_players {
  padding: 5px;
}

.podcast_player  {
  display: inline-bl0ock;
  overflow: hidden;
  border-radius: 13px;
  width: 83px;
  height: 83px;
  padding: 5px;
}

.podcast_player_image {
  border-radius: 13px;
  width: 3%;
  height: 3%;
}

a.store_link {
  font-size: xx-large;
  font-weight: bold;
  color: #ffff00d2;
}

a.store_link:link,
a.store_link:visited {
  text-decoration: none;
}

a.store_link:hover,
a.store_link:active {
  text-decoration: underline;
  color: #ff6600;
}