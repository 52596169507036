/* NAV MENU */
.navbar {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.navbar-brand,
.navbar-brand:hover {
  color: white;
}

.navbar-items {
  align-items: baseline;
}

.nav-link {
  color: gray;
}

.nav-link:visited {
  color: gray;
}

.nav-link:hover {
  color: white;
}

.nav-link:active {
  color: lightsalmon;
}

.nav-link.active,
.navbar-nav .show>.nav-link {
  color: brown !important;
}

.bg-12coffees {
  background-color: black;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

#basic-nav-dropdown {
  color: gray;
}

.dropdown-menu {
  background-color: gray;
}

.dropdown-item:active {
  background-color: gray;
  color: lightsalmon;
}

.menu-icon-mobile {
  color: #593602;
}

/* SOCIAL MEDIA LINKS */

.social-media-container {
  background-color: transparent;
  padding: 25px 50px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #FFFFFF;
}

a.instagram { 
  color: #CC00D6;
}

a.linkedin {
  color: #0e76a8;
}

a.coffee,
a.monkey {
  color: #593602;
}

img.buymeacoffee {
  height: 34px;
  width: 35px;
  margin-bottom: 1px;
  box-shadow: none;
  border: none;
  vertical-align: middle;
}

/* On screens that are 400px wide or less, the background image will be a smaller one (ToDo) */
@media screen and (max-width: 410px) {
  .top-menu {
    max-width: 50%;
  }

  .social-media-container {
    padding: 0;
  }
}

/* On screens that are 401px wide or more, the embedded video frame and merch image will be resized a little */
/* @media screen and (min-width: 401px) {
  .home-video {
    width: 80%;
    height: 100%;
    float: left;
  }
} */